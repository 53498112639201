import { createApp } from 'vue'
import VueClickAway from 'vue3-click-away'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import '@/assets/scss/style.scss'

import Toaster from '@meforma/vue-toaster'

createApp(App)
  .use(store)
  .use(router)
  .use(VueClickAway)
  .use(Toaster)
  .use(i18n)
  .mount('#app')
