<template>
  <div>
    <navbar/>
    <div class="container-xxl mt-3 mb-3">
      <header class="mb-4 text-center">
        <h2 style="font-weight: 900" v-html="$t('main_page.h2_title')"></h2>
      </header>

      <div class="card mb-3">
        <div class="card-body" style="padding-bottom: 0; padding-top: 0">
          <nav class="navbar navbar-expand-lg bg-body-tertiary">
            <div class="container-fluid" style="padding: 0">
              <span class="navbar-text">
                {{ $t('main_page.paginate.shown_from') }} {{ size * pageNumber + 1 }} {{ $t('main_page.paginate.by') }} {{
                  size * (pageNumber + 1) > this.filterItems.length
                    ? this.filterItems.length
                    : size * (pageNumber + 1)
                }} {{ $t('main_page.paginate.from') }} {{ this.filterItems.length }} {{
                  $t('main_page.paginate.records')
                }}
              </span>
              <div>
                <select
                  class="form-select"
                  v-model="selectType"
                >
                  <option value="" selected>{{ $t('main_page.filter_by_model_class') }}</option>
                  <option v-for="model_type in types"
                          :value="model_type.name"
                          :key="model_type.id">
                    {{ model_type.name }}
                  </option>
                </select>
              </div>
              <nav>
                <ul class="pagination" style="margin-bottom: 0">
                  <li class="page-item" :class="{disabled: pageNumber === 0}">
                    <a class="page-link" href="#" @click="prevPage">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <template v-if="pageNumber===0">
                    <li class="page-item active"><a class="page-link" href="#">1</a></li>
                    <li v-if="pageCount > 1" class="page-item"><a class="page-link" href="#" @click="pageNumber=1">2</a>
                    </li>
                    <li v-if="pageCount > 2" class="page-item"><a class="page-link" href="#" @click="pageNumber=2">3</a>
                    </li>
                  </template>

                  <template v-else-if="pageNumber>=pageCount - 1">
                    <li v-if="pageNumber - 2 >= 0" class="page-item"><a class="page-link" href="#"
                                                                        @click="pageNumber=pageNumber-2">{{
                        pageNumber - 1
                      }}</a></li>
                    <li v-if="pageNumber - 1 >= 0" class="page-item"><a class="page-link" href="#"
                                                                        @click="pageNumber=pageNumber-1">{{
                        pageNumber
                      }}</a></li>
                    <li class="page-item active"><a class="page-link" href="#">{{ pageNumber + 1 }}</a></li>
                  </template>

                  <template v-else>
                    <li class="page-item">
                      <a class="page-link" href="#" @click="pageNumber=pageNumber-1">{{ pageNumber }}</a>
                    </li>
                    <li class="page-item active">
                      <a class="page-link" href="#">{{ pageNumber + 1 }}</a></li>
                    <li class="page-item">
                      <a class="page-link" href="#" @click="pageNumber=pageNumber+1">{{ pageNumber + 2 }}</a>
                    </li>
                  </template>

                  <li class="page-item" :class="{disabled: pageNumber >= pageCount -1}">
                    <a class="page-link" href="#" aria-label="Next" @click="nextPage">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </nav>
        </div>
      </div>

      <main class="row g-3 flex-wrap justify-content-around">
        <div v-for="model in items" :key="model.id" class="col-sm-3 ship-model-item">
          <div class="card">
            <img class="card-img-top" :src="model.main_photo" :alt="model.model_name"/>
            <div class="card-body text-center">
              <div class="card-title">
                <router-link class="ship-model-name" :to="{name: 'model-info', params: {id: model.id}}"><h4>
                  {{ model.model_name }}</h4>
                </router-link>
              </div>
              <div class="card-text">
                <p v-if="model.model_class">{{ $t('ship_model.class') }}: {{ model.model_class }}</p>
                <p v-if="model.model_scale">{{ $t('ship_model.scale') }}: {{ model.model_scale }}</p>
                <p v-if="model.author">{{ $t('ship_model.author') }}: {{ model.author }}</p>
                <p class="city-country" v-if="model.city_country">{{ $t('ship_model.location') }}: {{
                    model.city_country
                  }}</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>

    <loading v-model:active="isLoading"
             loader="dots"
             :height="220"
             :width="160"
             color="#076DDA"
             :can-cancel="false"
             :is-full-page="true"/>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Navbar from './../components/NavMenu'
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapState } from 'vuex'

export default {
  name: 'ShipModels',
  components: {
    Loading,
    Navbar
  },
  data: () => ({
    size: 8,
    pageNumber: 0,
    selectType: ''
  }),
  methods: {
    nextPage () {
      if (this.pageNumber >= this.pageCount - 1) {
        return
      }
      this.pageNumber++
    },
    prevPage () {
      if (this.pageNumber === 0) {
        return
      }
      this.pageNumber--
    }
  },
  computed: {
    ...mapState(['isLoading', 'errorMessage', 'types', 'models', 'totalModelsCount']),
    filterItems () {
      let models = this.models
      if (this.selectType !== undefined && this.selectType !== '') {
        models = models.filter((v) => v.model_class === this.selectType)
      }
      return models
    },
    items () {
      const start = this.pageNumber * this.size
      const end = start + this.size
      return this.filterItems.slice(start, end)
    },
    pageCount () {
      const l = this.filterItems.length
      const s = this.size
      return Math.ceil(l / s)
    }
  },
  watch: {
    selectType (type) {
      if (type !== '') {
        this.pageNumber = 0
      }
    },
    errorMessage (message) {
      if (message !== '') {
        this.$toast.show(message, {
          type: 'error',
          position: 'top-right'
        })
      }
    }
  },
  created () {
    this.$store.dispatch('getShipTypes')
    this.$store.dispatch('getShipModels')
  }
}
</script>

<style lang="scss">
.ship-model-item {
  min-width: 300px;

  .card-title a {
    text-decoration: none
  }

  .card-text {
    p {
      margin-bottom: 0;
    }
  }
}
</style>
