<template>
  <router-view style="position: relative;"/>
  <div class="background-image-container">
    <div class="background-image-overlay"></div>
  </div>
</template>

<style lang="scss">
</style>
<script setup>
</script>
