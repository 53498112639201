<template>
  <div class="navbar-header">
    <div class="container-xxl">
      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
          <router-link class="navbar-brand" :to="{name: 'ship-models'}">
            <img src="./../assets/images/icon.svg" alt="icon" width="40" height="40">
            {{ $t('nav_menu.competition') }}
          </router-link>
          <ul class="navbar-nav me-auto flex-row">
            <li class="nav-item">
              <a href="/Положение_конкурс_судомоделистов_2023.pdf" target="_blank" class="nav-link">{{
                  $t('nav_menu.provision')
                }}</a>
            </li>
            <!--            <li class="nav-item">-->
            <!--              <router-link class="nav-link"-->
            <!--                           :to="{name: 'add-ship-models'}">{{ $t('nav_menu.apply_for_the_contest') }}-->
            <!--              </router-link>-->
            <!--            </li>-->
            <li class="nav-item">
              <router-link class="nav-link"
                           :to="{name: 'organizers'}">{{ $t('nav_menu.organizers') }}
              </router-link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <LanguageSwitcher/>
  </div>
</template>

<script>
import LanguageSwitcher from '@/components/LanguageSwitcher.vue'

export default {
  name: 'NavMenu',
  components: { LanguageSwitcher }
}
</script>

<style lang="scss">
.container-fluid {
  padding-top: 0.25em;
}

.navbar-brand {
  color: var(--bs-body-color);
  text-decoration: none;
  padding-top: 0 !important;
  padding-bottom: 0.1em !important;
  display: flex;
  align-items: center;

  img {
    margin-right: 20px;
  }
}

.navbar .nav-link {
  color: var(--bs-body-color);
  text-decoration: none;
}

.navbar-nav.flex-row {
  flex-wrap: wrap;

  .nav-item {
    margin-right: 10px;
  }
}

</style>
