<template>
  <div>
    <navbar/>

    <div class="container-xxl mt-3 mb-3">
      <div class="card add-ship-card">
        <div class="card-body">
          <header class="mb-5 text-center">
            <h2 class="mb-3">{{ $t('add_model_page.adding_new_model') }}</h2>
            <h4>{{ $t('add_model_page.fill_model_registration_model') }}</h4>
          </header>

          <main>
            <form v-if="addPageScreen === 'form'" ref="form" method="post" class="needs-validation was-validated">
              <h5 class="text-center">{{ $t('add_model_page.model_info_block') }}</h5>
              <div class="row g-3">
                <div class="col-md-4">
                  <label for="model-name" class="form-label">{{ $t('add_model_page.name') }}</label>
                  <input type="text" :placeholder="$t('add_model_page.name')" id="model-name" class="form-control"
                         required
                         v-model="model.name">
                  <div class="invalid-feedback">{{ $t('add_model_page.name_invalid') }}.</div>
                </div>

                <div class="col-md-4">
                  <label for="model-class" class="form-label">{{ $t('add_model_page.class') }}</label>
                  <select
                    class="form-select"
                    id="model-class"
                    v-model="model.type"
                    required
                  >
                    <option value="">{{ $t('add_model_page.class') }}...</option>
                    <option v-for="model_type in types"
                            :value="model_type.id"
                            :key="model_type.id">
                      {{ model_type.name }}
                    </option>
                  </select>
                  <div class="invalid-feedback">{{ $t('add_model_page.class_invalid') }}.</div>
                </div>

                <div class="col-md-4">
                  <label for="model-scale" class="form-label">{{ $t('add_model_page.scale') }}</label>
                  <div class="input-group">
                    <span class="input-group-text">1/</span>
                    <input type="text" :placeholder="$t('add_model_page.scale')" class="form-control" id="model-scale"
                           required v-model="model.scale">
                  </div>
                  <div class="invalid-feedback">{{ $t('add_model_page.scale_invalid') }}.</div>
                </div>
              </div>

              <hr class="my-4">

              <h5 class="text-center">{{ $t('add_model_page.participant_information_block') }}</h5>
              <div class="row g-3 flex-wrap">
                <div class="col-md-4">
                  <label for="surname" class="form-label">{{ $t('add_model_page.surname') }}</label>
                  <input type="text" class="form-control" id="surname" required
                         :placeholder="$t('add_model_page.surname')"
                         v-model="model.surname">
                  <div class="invalid-feedback">{{ $t('add_model_page.surname_invalid') }}.</div>
                </div>

                <div class="col-md-4">
                  <label for="firstName" class="form-label">{{ $t('add_model_page.first_name') }}</label>
                  <input type="text" class="form-control" id="firstName" :placeholder="$t('add_model_page.first_name')"
                         required v-model="model.firstName">
                  <div class="invalid-feedback">{{ $t('add_model_page.first_name_invalid') }}.</div>
                </div>

                <div class="col-md-4">
                  <label for="lastName" class="form-label">{{ $t('add_model_page.last_name') }}</label>
                  <input type="text" class="form-control" id="lastName" :placeholder="$t('add_model_page.last_name')"
                         v-model="model.lastName">
                  <!--                     required-->
                  <!--              <div class="invalid-feedback">{{ $t('add_model_page.last_name_invalid') }}.</div>-->
                </div>

                <div class="col-md-3">
                  <label for="dateOfBirth" class="form-label">{{ $t('add_model_page.dateOfBirth') }}</label>
                  <input type="date" class="form-control" id="dateOfBirth" required
                         min="1950-01-01"
                         :max="maxDate"
                         :placeholder="$t('add_model_page.dateOfBirth')"
                         v-model="model.dateOrBirth">
                  <div class="invalid-feedback">{{ $t('add_model_page.dateOfBirth_invalid') }}.</div>
                </div>

                <div class="col-md-9">
                  <label for="eduOrg" class="form-label">{{ $t('add_model_page.eduOrg') }}</label>
                  <input type="text" class="form-control" id="eduOrg" :placeholder="$t('add_model_page.eduOrg')"
                         required
                         v-model="model.eduOrg">
                  <div class="invalid-feedback">{{ $t('add_model_page.eduOrg_invalid') }}.</div>
                </div>

                <div class="col-md-4">
                  <label for="cityCountry" class="form-label">{{ $t('add_model_page.cityCountry') }}</label>
                  <input type="text" class="form-control" id="cityCountry"
                         :placeholder="$t('add_model_page.cityCountry')"
                         required v-model="model.cityCountry">
                  <div class="invalid-feedback">{{ $t('add_model_page.cityCountry_invalid') }}.</div>
                </div>

                <div class="col-md-5">
                  <label for="address" class="form-label">{{ $t('add_model_page.address') }}</label>
                  <input type="text" class="form-control" id="address" :placeholder="$t('add_model_page.address')"
                         required
                         v-model="model.address">
                  <div class="invalid-feedback">{{ $t('add_model_page.address_invalid') }}.</div>
                </div>

                <div class="col-md-3">
                  <label for="zipCode" class="form-label">{{ $t('add_model_page.zipCode') }}</label>
                  <input type="text" class="form-control" id="zipCode" :placeholder="$t('add_model_page.zipCode')"
                         required
                         v-model="model.zipCode">
                  <div class="invalid-feedback">{{ $t('add_model_page.zipCode_invalid') }}.</div>
                </div>

                <div class="col-md-3">
                  <label for="phone" class="form-label">{{ $t('add_model_page.phone') }}</label>
                  <input type="text" class="form-control" id="phone" :placeholder="$t('add_model_page.phone')" required
                         v-model="model.phone">
                  <div class="invalid-feedback">{{ $t('add_model_page.phone_invalid') }}.</div>
                </div>

                <div class="col-md-4">
                  <label for="email" class="form-label">{{ $t('add_model_page.email') }}</label>
                  <input type="email" class="form-control" id="email" :placeholder="$t('add_model_page.email')" required
                         v-model="model.email">
                  <div class="invalid-feedback">{{ $t('add_model_page.email_invalid') }}.</div>
                </div>

                <div class="col-md-12">
                  <label for="description" class="form-label">{{ $t('add_model_page.description') }}</label>
                  <textarea class="form-control" rows="3" id="description" required
                            :placeholder="$t('add_model_page.description')"
                            v-model="model.description"/>
                  <div id="emailHelp" class="form-text">{{ $t('add_model_page.description_help') }}.</div>
                </div>
              </div>

              <hr class="my-4">

              <h5 class="text-center">{{ $t('add_model_page.documents_for_participation_block') }}</h5>

              <div class="mb-3">
                <label for="document-upload-passport" class="form-label">{{ $t('add_model_page.passport') }}</label>
                <input
                  class="form-control"
                  type="file"
                  accept="application/pdf"
                  id="document-upload-passport"
                  :placeholder="$t('add_model_page.passport')"
                  @change="handleFileUpload('passport')"
                  ref="document-upload-passport">
                <div id="emailHelp" class="form-text">{{ $t('add_model_page.passport_help') }}</div>
              </div>

              <div class="mb-3">
                <label for="document-upload-drawing" class="form-label">{{ $t('add_model_page.drawing') }}</label>
                <input
                  class="form-control"
                  type="file"
                  accept="application/pdf"
                  id="document-upload-drawing"
                  required
                  :placeholder="$t('add_model_page.drawing')"
                  @change="handleFileUpload('drawing')"
                  :ref="`document-upload-drawing`">
                <div id="emailHelp" class="form-text">{{ $t('add_model_page.drawing_help') }}</div>
              </div>

              <div class="mb-3">
                <label for="document-upload-photos" class="form-label">{{ $t('add_model_page.photos') }}</label>
                <input
                  class="form-control"
                  type="file"
                  multiple
                  accept="image/*"
                  id="document-upload-photos"
                  :placeholder="$t('add_model_page.photos')"
                  @change="handleFileUpload('photos')"
                  :ref="`document-upload-photos`"
                  required>
                <div id="emailHelp" class="form-text">{{ $t('add_model_page.photos_help') }}</div>
              </div>

              <div class="mb-3">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    required
                  >
                  <label class="form-check-label">{{ $t('add_model_page.with') }}
                    <a target="_blank"
                       href="/Положение_конкурс_судомоделистов_2023.pdf">{{
                        $t('add_model_page.provision')
                      }}</a>
                    {{ $t('add_model_page.familiarized') }} </label>
                </div>
              </div>

              <hr class="my-4">

              <div class="mb-3">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    required
                  >
                  <label class="form-check-label">
                    {{ $t('add_model_page.with') }}
                    <a target="_blank" href="https://patriotsport.moscow/wp-content/uploads/2022/03/pril-3.pdf">
                      {{ $t('add_model_page.politics') }} </a> {{ $t('add_model_page.familiarized') }}
                  </label>
                </div>
              </div>

              <div v-if="errorMessage !== ''" class="alert alert-danger mb-3" role="alert">{{ errorMessage }}</div>

              <button class="w-100 btn btn-primary btn-lg" type="submit" @click.prevent="submitBtn">
                {{ $t('add_model_page.participate_btn') }}
              </button>
            </form>
            <div v-else-if="addPageScreen === 'success'">
              <h3>{{ $t('add_model_page.send_to_moderate_title') }}</h3>
              <h6 class="lead">{{ $t('add_model_page.send_to_moderate_text') }}
                <router-link :to="{name: 'model-info', params: {id: newModelId}}">{{
                    $t('add_model_page.link')
                  }}
                </router-link>
              </h6>
            </div>
          </main>
        </div>

      </div>
    </div>
    <loading v-model:active="isLoading"
             loader="dots"
             :height="220"
             :width="160"
             color="#076DDA"
             :can-cancel="false"
             :is-full-page="true"/>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Navbar from '../components/NavMenu'
import { mapState } from 'vuex'

export default {
  name: 'AddShipModel',
  components: {
    Loading,
    Navbar
  },
  computed: {
    ...mapState(['isLoading', 'errorMessage', 'types', 'hostUrl', 'addPageScreen', 'newModelId'])
  },
  data: () => ({
    maxDate: '',
    model: {
      name: '',
      type: '',
      scale: '',
      surname: '',
      firstName: '',
      lastName: '',
      description: '',
      dateOrBirth: '',
      eduOrg: '',
      cityCountry: '',
      email: '',
      phone: '',
      zipCode: '',
      address: '',
      passport_file: null,
      drawing_file: null,
      photos: []
    }
  }),
  methods: {
    handleFileUpload (id) {
      if (id === 'passport') {
        this.model.passport_file = this.$refs['document-upload-passport'].files[0]
      } else if (id === 'drawing') {
        this.model.drawing_file = this.$refs['document-upload-drawing'].files[0]
      } else if (id === 'photos') {
        this.model.photos = this.$refs['document-upload-photos'].files
      }
    },
    submitBtn () {
      if (!this.$refs.form.checkValidity()) {
        this.$toast.error('Все обязательные поля должны быть заполнены', {
          position: 'top-right'
        })
        return
      }

      if (this.model.email.indexOf('edu.mos') !== -1) {
        this.$toast.error('Почта edu.mos не поддерживается. Пожалуйста укажите другую почту', {
          position: 'top-right'
        })
      }

      this.$toast.info('Идёт обработка данных...', {
        position: 'top-right'
      })

      const data = structuredClone(this.model)
      data.dateOrBirth = Date.parse(data.dateOrBirth) / 1000
      data.scale = '1/' + data.scale
      this.$store.dispatch('addModel', data)
    }
  },
  watch: {
    errorMessage (message) {
      if (message !== '') {
        this.$toast.show(message, {
          type: 'error',
          position: 'top-right'
        })
      }
    },
    addPageScreen (screen) {
      if (screen === 'success') {
        this.$toast.show('Заявка отправлена на модерацию!', {
          type: 'success',
          position: 'top-right'
        })
      }
    }
  },
  created () {
    const maxD = new Date()
    maxD.setFullYear(maxD.getFullYear() - 5)
    this.maxDate = maxD.getFullYear() + '-' + maxD.getMonth() + '-' + maxD.getDate()
    this.$store.commit('setScreenPage', 'form')
    this.$store.dispatch('getShipTypes')
  }
}
</script>

<style scoped>

</style>
