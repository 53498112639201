import { createRouter, createWebHistory } from 'vue-router'
import ShipModels from '@/views/ShipModels'
import AddShipModel from '@/views/AddShipModel'
import ShipModelInfo from '@/views/ShipModelInfo'
import OrganizersPage from '@/views/OrganizersPage'

const routes = [
  {
    path: '/',
    name: 'ship-models',
    component: ShipModels
  },
  {
    path: '/add-model',
    name: 'add-ship-models',
    component: AddShipModel
  },
  {
    path: '/model/:id',
    name: 'model-info',
    component: ShipModelInfo
  },
  {
    path: '/organizers',
    name: 'organizers',
    component: OrganizersPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
