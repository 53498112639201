<template>
  <div class="model-info">
    <navbar/>
    <div class="container-xxl mt-3 mb-3">
      <header class="mb-4 text-center">
        <h2 class="mb-0 ship-model-name">{{ modelInfo.model_name }}</h2>
      </header>
      <main>
        <div class="text-center mb-4">
          <p v-if="modelInfo.model_class">{{ $t('ship_model.class') }}: {{ modelInfo.model_class }}</p>
          <p v-if="modelInfo.model_scale">{{ $t('ship_model.scale') }}: {{ modelInfo.model_scale }}</p>
          <p v-if="modelInfo.author">{{ $t('ship_model.author') }}: {{ modelInfo.author }}</p>
          <p class="city-country" v-if="modelInfo.city_country">{{ $t('ship_model.location') }}:
            {{ modelInfo.city_country }}</p>
          <p v-if="modelInfo.status !== undefined && modelInfo.status !== 1">Статус:
            <span v-if="modelInfo.status === 0" class="badge bg-warning">{{
                $t('ship_model.statuses.moderated')
              }}</span>
            <span v-else-if="modelInfo.status === 2" class="badge bg-danger">{{
                $t('ship_model.statuses.not_accepted')
              }}</span>
          </p>
        </div>

        <model-images
          v-if="modelInfo.main_photo || modelInfo.photos"
          :main-photo="modelInfo.main_photo"
          :drawing-file="modelInfo.model_drawing"
          :passport-file="modelInfo.model_passport"
          :photos="modelInfo.photos"
        />

        <hr>
        <div class="mt-3">
          <a v-if="modelInfo.model_passport" target="_blank" class="btn btn-success"
             :href="modelInfo.model_passport">{{ $t('ship_model.passport') }}</a>
          <a v-if="modelInfo.model_drawing" target="_blank" class="btn btn-primary mx-3"
             :href="modelInfo.model_drawing">{{ $t('ship_model.drawing') }}</a>
        </div>

        <div class="mt-3" style="" v-html="modelInfo.description"></div>
      </main>
    </div>
    <loading v-model:active="isLoading"
             loader="dots"
             :height="220"
             :width="160"
             color="#076DDA"
             :can-cancel="false"
             :is-full-page="true"/>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import { mapState } from 'vuex'
import 'vue-loading-overlay/dist/vue-loading.css'
import ModelImages from './../components/ModelImages'
import Navbar from '../components/NavMenu'

export default {
  name: 'ShipModelInfo',
  components: {
    Loading,
    ModelImages,
    Navbar
  },
  computed: {
    ...mapState(['isLoading', 'errorMessage', 'types', 'models', 'hostUrl', 'modelInfo'])
  },
  watch: {
    errorMessage (message) {
      if (message !== '') {
        this.$toast.show(message, {
          type: 'error',
          position: 'top-right'
        })
      }
    }
  },
  created () {
    this.$store.dispatch('getShopModelInfo', this.$route.params.id)
  }
}
</script>

<style lang="scss">
.model-info {
  p {
    margin-bottom: 0;
  }
}
</style>
