import { createStore } from 'vuex'
import axios from 'axios'
import { useRouter } from 'vue-router'

const dev = process.env.NODE_ENV === 'development'
let hostUrl = 'https://manager.cpvs.moscow'
if (dev) {
  hostUrl = 'http://127.0.0.1:8000'
}

export default createStore({
  state: {
    isLoading: true,
    addPageScreen: 'form',
    errorMessage: '',
    newModelId: '',
    modelInfo: {},
    hostUrl: hostUrl,
    models: [],
    totalModelsCount: 0,
    types: []
  },
  getters: {},
  mutations: {
    setLoading (state, value) {
      state.isLoading = value
    },
    setTypes (state, types) {
      state.types = types
    },
    setModels (state, models) {
      models.map(item => {
        if (item.main_photo) {
          item.main_photo = hostUrl + item.main_photo
        }
        if (item.model_drawing) {
          item.model_drawing = hostUrl + item.model_drawing
        }
        if (item.model_passport) {
          item.model_passport = hostUrl + item.model_passport
        }

        item.photos = item.photos.map(photo => {
          return hostUrl + photo
        })
        return item
      })
      state.models = models
    },
    setErrorMessage (state, errorMessage) {
      state.errorMessage = errorMessage
    },
    setNewModelId (state, id) {
      state.newModelId = id
    },
    setScreenPage (state, screen) {
      state.addPageScreen = screen
    },
    setModelInfo (state, info) {
      if (info.main_photo) {
        info.main_photo = hostUrl + info.main_photo
      }
      if (info.model_drawing) {
        info.model_drawing = hostUrl + info.model_drawing
      }
      if (info.model_passport) {
        info.model_passport = hostUrl + info.model_passport
      }
      info.photos = info.photos.map(photo => {
        return hostUrl + photo
      })
      state.modelInfo = info
    },
    setTotalModelsCount (state, count) {
      state.totalModelsCount = count
    }
  },
  actions: {
    getShopModelInfo ({ commit }, id) {
      commit('setErrorMessage', '')
      commit('setLoading', true)
      axios.get(hostUrl + '/modeling/get_ship_model_info/' + id).then((res) => {
        commit('setModelInfo', res.data)
      }).catch(() => {
        commit('setErrorMessage', 'Не удалось получить информацию о моделе!')
        useRouter().push({ name: 'ship-models' }).then()
      }).finally(() => {
        commit('setLoading', false)
      })
    },
    getShipTypes ({
      commit,
      state
    }) {
      if (state.types.lengthq > 0) {
        return
      }
      commit('setErrorMessage', '')
      commit('setLoading', true)
      axios.get(hostUrl + '/modeling/get_ship_model_types').then((res) => {
        commit('setTypes', res.data)
      }).catch(() => {
        commit('setErrorMessage', 'Не удалось получить классы моделей!')
      }).finally(() => {
        commit('setLoading', false)
      })
    },
    getShipModels ({ commit }) {
      commit('setErrorMessage', '')
      commit('setLoading', true)
      axios.get(hostUrl + '/modeling/get_ship_models').then((res) => {
        commit('setModels', res.data.models)
        commit('setTotalModelsCount', res.data.total_count)
      }).catch((error) => {
        console.log(error)
        commit('setErrorMessage', 'Не удалось получить модели!')
      }).finally(() => {
        commit('setLoading', false)
      })
    },
    addModel ({ commit }, model) {
      commit('setErrorMessage', '')
      commit('setLoading', true)
      const formData = new FormData()
      Object.keys(model).forEach(key => {
        if (key === 'photos') {
          Object.keys(model[key]).forEach(item => {
            formData.append('photos' + item, model[key][item])
          })
        }
        formData.append(key, model[key])
      })

      axios.post(hostUrl + '/modeling/add_ship_model', formData).then((res) => {
        if (res.data.status) {
          commit('setScreenPage', 'success')
          commit('setNewModelId', res.data.id)
        } else {
          commit('setErrorMessage', res.data.message)
        }
      }).catch((res) => {
        commit('setErrorMessage', res.data.message)
      }).finally(() => {
        commit('setLoading', false)
      })
    }
  },
  modules: {}
})
