<template>
  <div>
    <navbar/>
    <div class="container-xxl mt-3 mb-3">
      <div class="mb-4 text-center">
        <h2>Организаторы</h2>
      </div>

      <div class="row row-cols-1 row-cols-md-2 g-4">
        <organizer-card v-for="item in organizers"
                        :key="item.id"
                        :full-name="$t(`organizers_list.${item.id}.fullName`)"
                        :email="item.email"
                        :photo="item.photo"
                        :info=" $t(`organizers_list.${item.id}.text`)"
        />
      </div>

      <div class="mb-4 text-center">
        <h2>Судьи</h2>
      </div>

      <div class="row row-cols-1 row-cols-md-2 g-4">
        <organizer-card v-for="item in judges"
                        :key="item.id"
                        :full-name="$t(`organizers_list.${item.id}.fullName`)"
                        :email="''"
                        :photo="item.photo"
                        :info=" $t(`organizers_list.${item.id}.text`)"
        />
      </div>

      <div class="mb-4 text-center">
        <h2>Секретари</h2>
      </div>

      <div class="row row-cols-1 row-cols-md-2 g-4">
        <organizer-card v-for="item in secretaries"
                        :key="item.id"
                        :full-name="$t(`organizers_list.${item.id}.fullName`)"
                        :email="''"
                        :photo="item.photo"
                        :info=" $t(`organizers_list.${item.id}.text`)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from './../components/NavMenu'
import 'vue-loading-overlay/dist/vue-loading.css'
import OrganizerCard from '@/components/OrganizerCard'

export default {
  name: 'OrganizersPage',
  components: {
    Navbar,
    OrganizerCard
  },
  data: () => ({
    organizers: [
      {
        id: 1,
        email: 'SizovCB@edu.mos.ru',
        photo: require('./../assets/images/organizers/1.svg')
      },
      {
        id: 2,
        email: 'Samartsev@fsmr.ru',
        photo: require('./../assets/images/organizers/2.svg')
      }
    ],
    judges: [
      {
        id: 3,
        photo: require('./../assets/images/organizers/3.svg')
      },
      {
        id: 6,
        photo: require('./../assets/images/organizers/6.svg')
      },
      {
        id: 7,
        photo: require('./../assets/images/organizers/7.jpg')
      },
      {
        id: 8,
        photo: require('./../assets/images/organizers/8.svg')
      },
      {
        id: 12,
        photo: require('./../assets/images/organizers/12.jpg')
      },
      {
        id: 11
        // photo: require('./../assets/images/organizers/5.svg')
      }
    ],
    secretaries: [
      {
        id: 9,
        photo: require('./../assets/images/organizers/1.svg')
      },
      {
        id: 10,
        photo: require('./../assets/images/organizers/2.svg')
      }
    ]
  })
}
</script>

<style lang="scss">

</style>
