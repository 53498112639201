<template>
  <div class="mb-3 col">
    <div class="card organizer-card">
      <div class="row g-0">
        <div class="col-md-4 organizer-image">
          <img v-if="photo" :src="photo" class="img-fluid rounded-start" :alt="fullName">
          <div class="img-fluid rounded-start"></div>
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title" v-html="fullName"></h5>
            <p class="card-text" v-html="info"></p>
            <p class="card-text email" v-html="email"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrganizerCard',
  props: {
    photo: {
      type: String
    },
    fullName: {
      type: String
    },
    info: {
      type: String
    },
    email: {
      type: String,
      nullable: true,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
