<template>
  <div class="model-images" :class="{'full': isFull}">
    <div id="carouselExampleCaptions" class="carousel carousel-dark slide" data-bs-ride="false">
      <div class="carousel-inner" style="z-index: 5">
        <div v-for="item in items" :key="item.id" class="carousel-item" :class="{'active': item.id === slide}">
          <img :src="item.url" class="d-block" @click="isFull = !isFull" alt="...">
          <div v-if="item.name" class="carousel-caption d-none d-md-block">
            <h5>{{ $t(item.name) }}</h5>
          </div>
        </div>
      </div>
      <button @click="prev" class="carousel-control-prev" type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button @click="next" class="carousel-control-next" type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
    <div class="images-select my-3">
      <img class="mx-2" v-for="item in items" :key="item.url" @click="slide = item.id" :src="item.url" :alt="item.name">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModelImages',
  data: () => ({
    slide: 0,
    isFull: false
  }),
  props: {
    mainPhoto: {
      type: String
    },
    photos: {
      type: Array
    }
  },
  methods: {
    prev () {
      if (this.slide - 1 < 0) {
        this.slide = this.items.length - 1
      } else {
        this.slide--
      }
    },
    next () {
      if (this.slide + 1 >= this.items.length) {
        this.slide = 0
      } else {
        this.slide++
      }
    }
  },
  computed: {
    items () {
      let id = 0
      const items = []
      if (this.mainPhoto) {
        items.push({
          id: id++,
          name: 'ship_model.main_photo',
          url: this.mainPhoto
        })
      }

      if (this.photos) {
        this.photos.forEach(photo => {
          if (items.find((item) => String(item.url) === String(photo)) === undefined) {
            items.push({
              id: id++,
              url: photo
            })
          }
        })
      }
      return items
    }
  }
}
</script>

<style lang="scss">
.model-images {
  &.full {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    padding: 2em;
    background: #fff;

    .carousel {
      height: calc(100vh - 160px);

      .carousel-item.active {
        display: flex;
        align-items: center;
        height: calc(100vh - 200px);
      }

      img {
        cursor: zoom-out;
        height: auto;
        max-height: 100%;
      }
    }
  }

  .carousel-control-prev, .carousel-control-next, .carousel-indicators {
    z-index: 10;
  }

  .carousel-inner {
    z-index: 5;

    img {
      margin: 0 auto;
      height: 750px;
      max-width: 100%;
      cursor: zoom-in;
    }
  }

  .images-select {
    width: 100%;
    height: 100px;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    padding-bottom: 8px;

    img:first-child {
      margin-left: 0 !important;
    }

    img:last-child {
      margin-right: 0 !important;
    }

    &::-webkit-scrollbar {
      width: 12px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      width: 4px;
      background: #f4f4f4;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 7px -2px rgba(0, 0, 0, 0.1);
      width: 6px;
      background: #e9e9e9;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #e9e9e9;
    }
  }
}

@media (max-width: 768px) {
  .model-images {
    .carousel {
      img {
        height: auto;
      }
    }
  }
}
</style>
